import {initializeApp} from 'firebase/app';
import {initializeAuth, browserLocalPersistence, signInAnonymously, getIdTokenResult} from 'firebase/auth';
import {getStorage, ref as storageRef, getDownloadURL} from 'firebase/storage';
import {getDatabase, ref as dbRef, get, set} from 'firebase/database';
import { useEffect, useState } from 'react';

const DISABLE_CACHE = window.location.hostname === 'localhost';

const firebaseConfig = {
  apiKey: "AIzaSyCkzuEwR5fYBzA2Lfi_AnkL8EVLKGkVpHw",
  authDomain: "wedding-website-99722.firebaseapp.com",
  projectId: "wedding-website-99722",
  storageBucket: "wedding-website-99722.appspot.com",
  messagingSenderId: "915888297913",
  appId: "1:915888297913:web:505bd606b2173c13a84868"
};

export const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, {persistence: browserLocalPersistence});
const storage = getStorage(app);
const db = getDatabase(app);

// Promise that resolves once auth state settles initially
const authSettled = new Promise(resolve => {
  auth.onAuthStateChanged(resolve);
});

export async function signIn() {
  const {user} = await signInAnonymously(auth);
  return user;
}

export async function applyCode(code) {
  const url = `${window.location.protocol}//${window.location.host}/register`;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uid: auth.currentUser.uid,
      code,
    }),
  });
  if (response.ok) {
    await auth.currentUser.getIdTokenResult(true);
    return true;
  }
  return false;
}

export async function addEmail(email) {
  const url = `${window.location.protocol}//${window.location.host}/addEmail`;
  const response = await fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uid: auth.currentUser.uid,
      email,
    }),
  });
  if (response.ok) {
    await auth.currentUser.getIdTokenResult(true);
    window.localStorage.setItem('email', email);
    return true;
  }
  return false;
}

export async function isRegistered() {
  await authSettled;
  const user = auth.currentUser;
  const result = user && (await getIdTokenResult(auth.currentUser, true)).claims.registered === true;
  window.localStorage.setItem(cacheKey('bypass'), result);
  return result;
}

export async function getEmail() {
  if (window.localStorage.getItem('email')) {
    return window.localStorage.getItem('email');
  }
  await authSettled;
  const user = auth.currentUser;
  const email = (user && (await getIdTokenResult(auth.currentUser, true)).claims.assocEmail) || null;
  if (email) {
    window.localStorage.setItem('email', email);
  }
  return email;
}

function rsvpKey(email) {
  return `/responses/${email.replace(/\./g, '?').toLowerCase().trim()}`;
}

class ContentLoader {
  _cache = {};

  constructor() {
    const cache = window.localStorage.getItem(cacheKey('cache'));
    if (cache) {
      this._cache = JSON.parse(cache);
    }
  }

  async getImage(name) {
    if (this._cache[name]) {
      return this._cache[name];
    }
    const url = await getDownloadURL(storageRef(storage, `public/${name}`));
    this._cache[name] = url;
    this._persistCache();
    return url;
  }

  async getPageContent(name) {
    let content = this._cache['content'];
    if (!content || !content[name]) {
      const snap = await get(dbRef(db, '/main'));
      content = snap.val();
      this._cache['content'] = content;
      this._persistCache();
    }

    return content[name];
  }

  async getGuestList() {
    const snap = await get(dbRef(db, '/attendees'));
    return snap.val();
  }

  async getParty(email) {
    let party = this._cache['partyhearty'];
    if (!party) {
      const snap = await get(dbRef(db, rsvpKey(email)));
      party = snap.val();
      this._cache['partyhearty'] = party;
      this._persistCache();
    }
    
    return party;
  }

  async updateRsvp(email, party) {
    set(dbRef(db, rsvpKey(email)), party);
    this._cache['partyhearty'] = party;
    this._persistCache();
  }

  _persistCache() {
    if (DISABLE_CACHE) return;
    window.localStorage.setItem(cacheKey('cache'), JSON.stringify(this._cache));
  }
}

function cacheKey(key) {
  const now = new Date();
  return `1.3=${now.getDate()}.${now.getMonth() + 1}.${now.getFullYear()}=${key}`;
}

export function bypassAuthState() {
  if (DISABLE_CACHE) return false;
  const bypass = window.localStorage.getItem(cacheKey('bypass'));
  return bypass === 'true';
}

export const contentLoader = new ContentLoader();

// Helper hooks
export function usePageState(pageName) {
  const [page, setPage] = useState({});
  useEffect(() => {
    contentLoader.getPageContent(pageName).then(setPage);
  }, [pageName]);
  return page;
}

export function useImage(imageName) {
  const [image, setImage] = useState('');
  useEffect(() => {
    contentLoader.getImage(imageName).then(setImage);
  }, [imageName]);
  return image;
}

export function useRegisteredParty(connectedEmail) {
  const [party, setParty] = useState(null);
  useEffect(() => {
    (async () => {
      const email = await getEmail();
      if (email) {
        setParty(await contentLoader.getParty(email));
      }
    })();
  }, connectedEmail !== undefined ? [connectedEmail] : []);
  return party;
}