import './Title.css';
import separator from '../../svg/sep.svg';

export function Title({children}) {
  return (
    <div className='page-title'>
      {children}
      <img src={separator} />
    </div>
  )
}

export function SubTitle({children}) {
  return (
    <div className='page-subtitle'>
      {children}
    </div>
  )
}
