export function GuestList({party, ifNobody}) {
  const core = party.names
      .filter(name => party.namesMapped[name]);
  if (party.plusOne && party.plusOneName) {
    core.push(party.plusOneName);
  }

  const [last, ...first] = core.reverse();

  let guests = <></>;
  if (first.length) {
    guests = <span>
      {
        first.reverse().map((name, i) => {
          return <span key={i}><b>{name}</b>, </span>;
        })
      }
      and <b>{last}</b>
    </span>
  } else if (last) {
    // Only one name
    guests = <span><b>{last}</b></span>;
  } else {
    // No one is attending
    guests = <span><b>{ifNobody}</b></span>;
  }
  return guests;
}

export function isAnyoneAttending(party) {
  return party.names.filter(n => party.namesMapped[n]).length;
}