import { useEffect, useState } from 'react';
import TextBlock from '../../components/textblock/TextBlock';
import {Title} from '../../components/title/Title';
import { contentLoader, usePageState } from '../../firebase';
import './FAQ.css';

const IMAGE_BASE = 'faq/';

function FAQ() {
  const page = usePageState('faq');
  const [images, setImages] = useState({});

  useEffect(() => {
    let promise = Promise.resolve();
    let final = {};
    for (const [i, item] of (page.items || []).entries()) {
      if (item.img) {
        promise = promise.then(async () => {
          const img = await contentLoader.getImage(`${IMAGE_BASE}${item.img}`);
          final[i] = img;
        });
      }
    }
    promise.then(() => setImages(final));
  }, [page]);

  return (
    <div className='page'>
      <Title>Frequently asked questions</Title>
      <div className='faq-container'>
        {page.items?.map(({q, a}, i) => <Item q={q} a={a} img={images[i]} key={i}/>)}
      </div>
    </div>
  );
}

function Item({q, a, img}) {
  return (
    <div>
      <div className='faq-item'>
        <div className='bullet'>❧</div>
        <div>
          <div className='q'>{q}</div>
          <div className='a'><TextBlock content={a}/></div>
        </div>
      </div>
      {img ? <div className='faq-img'><img src={img}/></div> : <></>}
    </div>
  );
}

export default FAQ;