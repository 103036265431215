import { usePageState } from '../../firebase';
import { useWindowWidth } from '../../window_size';
import './ImageGrid.css';

function ImageGrid({images}) {
  const split = splitPairs(images);
  return (
    <div className='image-grid'>
      {split.map((row, i) => <ImageRow images={row} index={i} key={i}/>)}
    </div>
  );
}

function ImageRow({images, index}) {
  // Hacky hack hack
  const page = usePageState('story');
  const windowWidth = useWindowWidth();
  return (
    <div className='row'>
      {images.map((src, i) => <div
          className='container'
          key={src}
          style={!i && windowWidth > 850 ? {
            minWidth: `${page.ratios?.[index]}%`,
            maxWidth: `${page.ratios?.[index]}%`} : {}}><img src={src}/></div>)}
    </div>
  )
}

function splitPairs(images) {
  const split = [];
  for (let i = 0; i < images.length; i += 2) {
    const row = [images[i]];
    if (i + 1 < images.length) {
      row.push(images[i + 1]);
    }
    split.push(row);
  }
  return split;
}

export default ImageGrid;