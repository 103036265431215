import { useState } from 'react';
import Spinner from '../../components/spinner/Spinner';
import { applyCode, signIn } from '../../firebase';
import flourish from '../../svg/sep.svg';

import './Register.css';

const STARTING_INSTRUCTIONS = 'Please enter the code:';
const FAILURE_INSTRUCTIONS = 'Please try again:';

function Register() {
  const [code, setCode] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [instructions, setInstructions] = useState(STARTING_INSTRUCTIONS);

  async function handleSubmit(e) {
    setIsProcessing(true);
    e.preventDefault();
    await signIn();  // Make sure we're signed in
    if(!(await applyCode(code))) {
      setInstructions(FAILURE_INSTRUCTIONS);
    }
    setIsProcessing(false);
  }

  return (
    <div className='page register-page'>
      <form onSubmit={handleSubmit}>
        <div className='instructions'>{instructions}</div>
        <div className='input-container'>
          <img className='flourish left' src={flourish}/>
          <input type='text' className='input-text' value={code} onChange={e => setCode(e.target.value)} />
          <img className='flourish' src={flourish}/>
        </div>
        {
          isProcessing ? <Spinner type='small'/> : <button className='button' type='submit'>Submit</button>
        }
      </form>
    </div>
  );
}

export default Register;
