import {SubTitle, Title} from '../../components/title/Title';
import { useImage, usePageState, useRegisteredParty } from '../../firebase';
import './Details.css';
import daffodil from '../../svg/daffodil.svg';
import daffodil2 from '../../svg/daffodil2.svg';
import leaf from '../../svg/leaf.svg';
import TextBlock from '../../components/textblock/TextBlock';
import { useWindowWidth } from '../../window_size';
import { useState } from 'react';
import Toggle from '../../components/toggle/Toggle';

const SITE_IMAGE = 'details/site.jpg';
const PAGE_PADDING = 32;

const ICONS = [
  daffodil,
  leaf,
  daffodil2,
];

function Details() {
  const page = usePageState('details');
  const siteImage = useImage(SITE_IMAGE);
  const windowWidth = useWindowWidth();
  const party = useRegisteredParty();
  const [showRehearsalLoc, setShowRehearsalLoc] = useState(false);

  const iframeWidth = windowWidth <= 850 ? windowWidth - PAGE_PADDING * 2 : 600;
  const timeline = [...(page.timeline || [])];

  if (party?.rehearsal && page?.rehearsal) {
    timeline.unshift(page.rehearsal.timelineItem);
  }

  const mapUrl = (showRehearsalLoc ? page?.rehearsalUrl : page?.mapUrl) || '';

  return (
    <div className='page details'>
      <Title>Details</Title>
      <div className='content'>
        <div className='text halvsies'>
          {page.overview}
          <SubTitle>Timeline</SubTitle>
          {
            timeline.map(({when, what, where}, i) => 
              <TimelineItem when={when} what={what} where={where} key={i} icon={ICONS[i % ICONS.length]}/>
            )
          }
        </div>
        <div className={`map-container halvsies ${party?.rehearsal ? 'has-rehearsal' : ''}`}>
          <iframe width={iframeWidth} loading="lazy" allowFullScreen
              src={mapUrl}></iframe>
          {
            party?.rehearsal ? <div className='map-toggle'><Toggle
              optionA={{
                title: 'Venue',
                onClick: () => setShowRehearsalLoc(false),
              }}
              optionB={{
                title: 'Rehearsal dinner',
                onClick: () => setShowRehearsalLoc(true)
              }}
            /></div>  : <></>
          }
        </div>
      </div>
      {party?.rehearsal ? <RehearsalInfo text={page?.rehearsal?.text}/> : <></>}
      <SubTitle>Where to stay</SubTitle>
      <TextBlock content={page.whereToStay || ''}/>
      <div className='footer-image'>
        <img src={siteImage}/>
      </div>
    </div>
  );
}

function TimelineItem({when, what, where, icon}) {
  return (
    <div className='timeline-item'>
      <div>
        <span className='when'>{when}</span><img src={icon} /><span className='what'>{what}</span>
      </div>
      <div>
        <span className='where'>{where}</span>
      </div>
    </div>
  )
}

function RehearsalInfo({text}) {
  return (
    <>
      <SubTitle>Rehearsal dinner</SubTitle>
      <TextBlock content={text || ''}/>
    </>
  )
}

export default Details;

{/* <div className='map-toggle'>
              <a href='#/' onClick={() => setShowRehearsalLoc(false)}>Venue</a>
              <a href='#/' onClick={() => setShowRehearsalLoc(true)}>Rehearsal dinner</a>
            </div> */}