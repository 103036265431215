import { useEffect, useState } from 'react';
import TextBlock from '../../components/textblock/TextBlock';
import { Title } from '../../components/title/Title';
import { contentLoader, usePageState } from '../../firebase';
import ImageGrid from './ImageGrid';
import './Story.css';
import separator from '../../svg/big-sep.svg';

const IMAGE_BASE = 'story/';

function Story() {
  const page = usePageState('story');
  const [imageNames, setImageNames] = useState([]);
  const imageCount = page.imageCount || 0;

  useEffect(() => {
    const images = [];
    for (let i = 1; i <= imageCount; i++) {
      images.push(`${IMAGE_BASE}${i}.jpg`);
    }
    Promise.all(images.map(img => contentLoader.getImage(img))).then(setImageNames);
  }, [imageCount]);

  return (
    <div className='page story'>
      <Title>Our story</Title>
      <TextBlock content={page.desc || ''} />
      <div className='separator'><img src={separator} /></div>
      <ImageGrid images={imageNames}/>
    </div>
  );
}

export default Story;