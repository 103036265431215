import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useWindowWidth } from '../../window_size';
import './Header.css';

const MIN_WIDTH = 850;

const MENU_OPTIONS = [
  {
    name: 'Details',
    path: '/info',
  },
  {
    name: 'Our story',
    path: '/story',
  },
  null,
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'Registry',
    path: '/registry',
  }
];

function Header({isRegistered}) {
  const {pathname} = useLocation();
  const windowWidth = useWindowWidth();
  const [menuOpen, setMenuOpen] = useState(false);

  const optionsFiltered = windowWidth > MIN_WIDTH ?
      MENU_OPTIONS : MENU_OPTIONS.filter(opt => opt);
  const routeOptions = optionsFiltered.map(opt => {
    if (!opt) {
      return <Names key='names'/>
    }
    return <MenuOption
        key={opt.path}
        path={opt.path}
        name={opt.name}
        isSelected={pathname === opt.path}/>;
  });

  useEffect(() => {
    const root = document.querySelector('.page-content');
    if (menuOpen) {
      root.classList.add('menu-open');
    } else {
      root.classList.remove('menu-open');
    }
  }, [menuOpen]);

  // Close the menu when pathname changes or the user clicks beyond the left nav
  useEffect(() => {
    return document.addEventListener('click', e => {
      if (e.clientX > 200) {
        setMenuOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  function openMenu() {
    setMenuOpen(true);
  }

  // For the full-size header:
  if (windowWidth > MIN_WIDTH) {
    return (
      <div className='header-container'>
        <div className='header'>
          {
            !isRegistered ? <Names /> : routeOptions
          }
        </div>
        <div className='border'></div>
      </div>
    );
  } else {
    return (
      <div className='header-container'>
        {
          !isRegistered ? <></> :
            <div className='left-nav'>
              <div className='left-nav-border'></div>
              <div className='items'>{routeOptions}</div>
            </div>
        }
        <div className='header'>
          {
            !isRegistered ? <></> :
              <div className='kebab' onClick={() => {
                if (!menuOpen) openMenu();
              }}>☰</div>
          }
          <Names />
        </div>
        <div className='border'></div>
      </div>
    )
  }
}

function MenuOption(props) {
  const {isSelected, path, name} = props;
  const classes = 'menu-item' + (isSelected ? ' selected' : '');

  return (
    <div className={classes}>
      <Link to={path}>{name}</Link>
    </div> 
  )
}

function Names() {
  return (
    <div className='title'>
      <Link to='/'>
        <span className='name'>Katrina</span>&nbsp;&amp;&nbsp;<span className='name'>Sam</span>
      </Link>
    </div>
  )
}

export default Header;