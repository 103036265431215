import { Link } from 'react-router-dom';
import './TextBlock.css';

function TextBlock({content}) {
  const parts = content.split(/(\|[^|]*\|[^|]*\|)|&&&|(\\[^\\]*\\)/);
  return (
    <div className='text-block'>
    {
      parts.map((part, i) => {
        if (part === undefined) { // Line break
          // Line breaks are two undefineds in a row
          return parts[i + 1] === undefined ? <div className='par' key={i}></div> : <></>;
        }
        if (part.startsWith('|')) {
          const [text, link] = part.split('|').filter(i => i !== '');
          if (link.startsWith('!')) {
            // Local link
            return <Link to={link.slice(1)} key={i}>{text}</Link>
          } else {
            return <a href={link} target='_blank' rel='noopener noreferrer' key={i}>{text}</a>;
          }
        } else if (part.startsWith('\\')) {
          const [text] = part.split('\\').filter(i => i !== '');
          return <i key={i}>{text}</i>;
        }  else {
          return <span key={i}>{part}</span>;
        }
      })
    }
    </div>
  );
}

export default TextBlock;