import { useImage, usePageState, useRegisteredParty } from '../../firebase';
import './Landing.css';
import separator from '../../svg/big-sep.svg';
import { Link } from 'react-router-dom';
import { GuestList } from '../../components/guestlist/GuestList';
import { isRsvpExperimentEnabled } from '../../rsvptest';

const MAIN_IMAGE_NAME = 'landing/main.jpg';

function Landing() {
  const mainImage = useImage(MAIN_IMAGE_NAME);
  const page = usePageState('landing');
  
  return (
    <div className='landing-page page'>
      <div className='contents'>
        <img className='main-image' src={mainImage} />
        <div className='main-info'>
          <div className='when'>{page.when}</div>
          <div className='where'>{page.where}</div>
          <div className='separator'><img src={separator} /></div>
          <div className='join'>Please join us</div>
          {isRsvpExperimentEnabled() ? <RsvpDetails /> : <></>}
        </div>
      </div>
    </div>
  );
}

function RsvpDetails() {
  const party = useRegisteredParty();
  return (
    <>
      <Link to='/rsvp'>
        <button type='button' className='button'>{party ? 'Update RSVP details' : 'RSVP'}</button>
      </Link>
      {party ? 
        <div className='rsvp-details'>
          <GuestList party={party} ifNobody='No one from your party' /> planning to attend.
        </div>
        : <></>
      }
    </>
  );
}

export default Landing;