import Landing from './pages/landing/Landing';
import Register from './pages/register/Register';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {useEffect, useState} from 'react';
import { isRegistered, auth, signIn, bypassAuthState } from './firebase';
import { onIdTokenChanged } from 'firebase/auth';
import Header from './components/header/Header';
import Details from './pages/details/Details';
import FAQ from './pages/faq/FAQ';
import Spinner from './components/spinner/Spinner';
import Story from './pages/story/Story';
import Registry from './pages/registry/Registry';
import RSVP from './pages/rsvp/RSVP';
import { checkForFlag, isRsvpExperimentEnabled } from './rsvptest';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authStateKnown, setAuthStateKnown] = useState(false);
  const bypass = bypassAuthState();
  checkForFlag();

  useEffect(() => {
    onIdTokenChanged(auth, async () => {
      await signIn();
      setIsLoggedIn(await isRegistered());
      setAuthStateKnown(true);
    });
  }, []);

  const mainApp = <Switch>
    <Route path="/info">
      <Details />
    </Route>
    <Route path="/faq">
      <FAQ />
    </Route>
    <Route path="/story">
      <Story />
    </Route>
    <Route path="/registry">
      <Registry />
    </Route>
    <Route path="/rsvp">
      {isRsvpExperimentEnabled() ?
        <RSVP /> : <Landing />
      }
    </Route>
    <Route path="/">
      <Landing />
    </Route>
  </Switch>;

  let content;
  if (bypass) {
    content = mainApp;
  } else if (!authStateKnown) {
    content = <div className='page'><Spinner/></div>;
  } else if (!isLoggedIn) {
    content = <Register />;
  } else {
    content = mainApp;
  }

  return (
    <div className='page-content'>
      <Router>
        <Header isRegistered={isLoggedIn || bypass} />
        {content}
      </Router>
    </div>
  );
}

// Pages
//  - Basic info
//  - FAQ
//  - Photos
//  - Things to do

export default App;
