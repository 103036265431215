import { useState } from 'react';
import './Toggle.css';

function Toggle({optionA, optionB}) {
  const [selectedOption, setSelectedOption] = useState(optionA);

  function clickedOption(option) {
    option.onClick();
    setSelectedOption(option);
  }

  const optionAClassName = selectedOption.title === optionA.title ? 'option selected' : 'option';
  const optionBClassName = selectedOption.title === optionB.title ? 'option selected' : 'option'; 

  return (
    <div className='toggle-container'>
      <div className='toggle'>
        <span className={optionAClassName}>
          <a href='#/' onClick={() => clickedOption(optionA)}>{optionA.title}</a>
        </span>
        <span className={optionBClassName}>
          <a href='#/' onClick={() => clickedOption(optionB)}>{optionB.title}</a>
        </span>
      </div>
    </div>
  );
}

export default Toggle;