import { useEffect, useState } from 'react';

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    return window.addEventListener('resize', debounce(50, () => {
      setWindowWidth(window.innerWidth);
    }));
  }, []);

  return windowWidth;
}

function debounce(ms, cb) {
  let timeout = null;
  return () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      cb();
    }, ms);
  };
}