import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GuestList, isAnyoneAttending } from '../../components/guestlist/GuestList';
import Spinner from '../../components/spinner/Spinner';
import { SubTitle, Title } from '../../components/title/Title';
import { addEmail, contentLoader, getEmail, usePageState, useRegisteredParty } from '../../firebase';
import './RSVP.css';

function makeConfiguredParty(party, names, plusOne, attendingRehearsal, mealSelections) {
  const configured = {
    ...party,
    namesMapped: names || party.names.reduce((map, n) => {
      map[n] = false;
      return map;
    }, {}),
    plusOneName: plusOne || null,
    isPartyAttendingRehearsal: attendingRehearsal !== undefined ? attendingRehearsal : false,
    mealSelections: mealSelections || party.names.reduce((map, n) => {
      map[n] = Meals.UNKNOWN;
      return map;
    }, {}),
  };

  if (configured.plusOne) {
    configured.mealSelections['p1'] = mealSelections?.['p1'] || Meals.UNKNOWN;
  }
  return configured;
}

function migrateMealSelections(party) {
  // If the party already registered without a meal selection field, add it now
  if (!party.mealSelections) {
    party.mealSelections = party.names.reduce((map, n) => {
      map[n] = Meals.UNKNOWN;
      return map;
    }, {});

    if (party.plusOne) {
      party.mealSelections['p1'] = Meals.UNKNOWN;
    }
  }
  return party;
}

function RSVP() {
  const [connectedEmail, setConnectedEmail] = useState(null);
  const [isConnectingEmail, setIsConnectingEmail] = useState(false);
  const [emailConnectionFailure, setEmailConnectionFailure] = useState(false);
  const [prefilledParty, setPrefilledParty] = useState(null);
  const [selectedParty, setSelectedParty] = useState(prefilledParty);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const history = useHistory();

  // Tie the prefilled party to the selected party
  useEffect(() => {
    setSelectedParty(prefilledParty);
  }, [prefilledParty])

  // Only run once on page load; setConnectedEmail can be called separately
  useEffect(() => {
    getEmail().then(email => {
      setConnectedEmail(email);
    });
  }, []);

  // Don't finish connect email flow until the party (or lack thereof) is
  // retrieved
  useEffect(() => {
    if (connectedEmail) {
      contentLoader.getParty(connectedEmail).then(party => {
        setPrefilledParty(migrateMealSelections(party));
      }).catch(() => setEmailConnectionFailure(true))
      .then(() => setIsConnectingEmail(false));
    }
  }, [connectedEmail]);

  async function onAttachEmail(email) {
    setIsConnectingEmail(true);
    if (!email) return;
    let success = false;
    try {
      success = await addEmail(email);
    } catch {}
    if (success) {
      setConnectedEmail(email);
    } else {
      setEmailConnectionFailure(true);
      setIsConnectingEmail(false);
    }
  }

  function onPartyChosen(party) {
    // If there's a prefilled party, then we're adding a new party to this one.
    const configured = makeConfiguredParty(party);
    if (isAdding) {
      // Merge
      setSelectedParty({
        plusOne: configured.plusOne || selectedParty.plusOne,
        plusOneName: selectedParty.plusOneName,
        names: [...selectedParty.names, ...configured.names.filter(n => !selectedParty.names.includes(n))],
        namesMapped: {
          ...selectedParty.namesMapped,
          ...configured.namesMapped,
        },
        rehearsal: selectedParty.rehearsal || configured.rehearsal,
        isPartyAttendingRehearsal: selectedParty.isPartyAttendingRehearsal,
        mealSelections: {
          ...(selectedParty.mealSelections || {}),
          ...configured.mealSelections,
        }
      });
      setIsAdding(false);
    } else {
      setSelectedParty(configured);
    }
  }

  function onConfirm() {
    contentLoader.updateRsvp(connectedEmail, selectedParty);
    history.push('/');
  }

  let step = <EmailEntry onSubmit={onAttachEmail} isError={emailConnectionFailure} isProcessing={isConnectingEmail} />;
  if (connectedEmail && (!selectedParty || isAdding) && !isConnectingEmail) {
    step = <NameLookup onPartyChosen={onPartyChosen} isAdding={isAdding} onCancel={() => setIsAdding(false)}/>;
  } else if (connectedEmail && selectedParty && !isConfirming) {
    step = <DetailsForm
        party={selectedParty}
        updateParty={setSelectedParty}
        onComplete={() => setIsConfirming(true)}
        showBackButton={!prefilledParty}
        onAdd={() => setIsAdding(true)}
        onBack={() => setSelectedParty(null)}/>;
  } else if (selectedParty && isConfirming) {
    step = <Confirm
        party={selectedParty}
        onBack={() => setIsConfirming(false)}
        onSetRehearsal={isPartyAttendingRehearsal => setSelectedParty({
          ...selectedParty,
          isPartyAttendingRehearsal
        })}
        onConfirm={onConfirm}/>;
  }

  return (
    <div className='rsvp-page page'>
      <div className='contents'>
        <Title>RSVP</Title>
        {step}
      </div>
    </div>
  );
}

function EmailEntry({onSubmit, isError, isProcessing}) {
  const [email, setEmail] = useState('');
  return (
    <div className='email-pre-setup'>
      <div className='email-info'>
        Please share your email so we can reach you with updates and
        additional information as we get closer to the wedding. You
        will also be able to retrieve your RSVP details on other
        computers using your email.
      </div>
      <form onSubmit={e => {e.preventDefault(); onSubmit(email);}}>
        <div className='spinner-box'>
          <input className='input-text' type='text' value={email} onChange={e => setEmail(e.target.value)} />
          {
            isError ? <b>There was an error connecting your email. Please try again later</b> : <></>
          }
          {
            isProcessing ? <Spinner type='small' /> : 
              <button className='button' type='submit'>Next</button>
          }
        </div>
      </form>
    </div>
  );
}

function NameLookup({onPartyChosen, isAdding, onCancel}) {
  const [search, setSearch] = useState('');
  const [guestList, setGuestList] = useState([]);

  useEffect(() => {
    contentLoader.getGuestList().then(setGuestList);
  }, []);

  let found = [];
  if (search.length >= 3) {
    found = guestList.filter(party => {
      return party.names.find(n => n.toLowerCase().includes(search.toLowerCase()));
    });
  }

  return (
    <div className='name-lookup'>
      <div className='name-title'>
        {isAdding ? `Enter the name of the person you'd like to add:` : `Please enter the name of someone in your party`}
      </div>
      
      <input className='input-text' type='text' value={search} onChange={e => setSearch(e.target.value)} />
      {
        found.map((p, i) => <PartyListing names={p.names} key={i} onSelect={() => onPartyChosen(p)}/>)
      }
      <div className='actions'>
      {
        isAdding ? <button className='button secondary' onClick={onCancel}>Cancel</button> :
        <></>
      }
      </div>
    </div>
  );
}

function PartyListing({names, onSelect}) {
  return (
    <div className='party-listing' onClick={onSelect}>
      {names.map(name => (
        <div className='name-item' key={name}>
          <span>{name}</span>
        </div>
      ))}
    </div>
  )
}

function DetailsForm({party, updateParty, onComplete, onBack, onAdd, showBackButton}) {
  const {names, plusOne, plusOneName, namesMapped, mealSelections} = party;
  function updateSelected(name) {
    updateParty(makeConfiguredParty(party, {
      ...namesMapped,
      [name]: !namesMapped[name],
    }, party.plusOneName, party.isPartyAttendingRehearsal, party.mealSelections));
  }

  function updateMeal(name, meal) {
    updateParty(makeConfiguredParty(party, namesMapped,
      party.plusOneName, party.isPartyAttendingRehearsal, {
        ...mealSelections,
        [name]: meal,
      }));
  }

  function updatePlusOne(name) {
    updateParty(makeConfiguredParty(party, namesMapped, name, party.isPartyAttendingRehearsal, party.mealSelections));
  }

  return (
    <div className='details-form'>
      <span>
        Please fill in a few more details! Let us know who from your party
        is attending.<br/>
        <b>Registering for another guest as well?</b>
        &nbsp;
        <a role='button' href='#' onClick={onAdd}>Add guests</a>.<br/><br/>
        Make sure to click through to the next page and submit if you're making changes!
      </span>
      <div className='names-container'>
        {names.map(name => (
          <NameBox
              key={name}
              name={name}
              isSelected={namesMapped[name]}
              onClick={() => {
                updateSelected(name);
              }}
              updateMeal={updateMeal}
              mealSelection={mealSelections[name]}/>
        ))}
        {plusOne ? <PlusOneBox
            name={plusOneName}
            setName={updatePlusOne}
            meal={mealSelections['p1']}
            setMeal={m => updateMeal('p1', m)}/> : <></>}
      </div>
      <div className='actions'>
        { showBackButton ?
          <button className='button secondary' onClick={onBack}>Back</button> :
          <Link to='/'>
            <button className='button secondary'>Cancel</button>
          </Link>
        }
        <button className='button' onClick={onComplete}>Next</button>
      </div>
    </div>
  );
}

function NameBox({name, onClick, isSelected, updateMeal, mealSelection}) {
  const inputId = `input-${name.replace(/ /g, '')}`;

  return (
    <div className='name-box'>
      <div className='name'>{name}</div>
      <div className='options'>
        <MealSelection meal={mealSelection} hidden={!isSelected} setMeal={m => updateMeal(name, m)}/>
        <div className='attending'>
          <label htmlFor={inputId}>Attending?</label>
          <input id={inputId} type='checkbox' checked={isSelected} onChange={onClick}/>
        </div>
      </div>
    </div>
  );
}

function PlusOneBox({name, setName, meal, setMeal}) {
  const [checked, setChecked] = useState(!!name);

  function toggleChecked(checked) {
    setChecked(checked);
    if (!checked) {
      setName('');
    }
  }

  const checkbox = <>
    <label htmlFor="p1check">+1?</label>
    <input id="p1check" type='checkbox' checked={checked} onChange={e => toggleChecked(e.target.checked)}/>
  </>

  // Two different UIs here
  if (checked) {
    return (
      <div className='name-box plus-one'>
        <div className='plus-one-input-box options'>
          <input className='input-text filler' type='text' value={name || ''} onChange={e => setName(e.target.value)} />
        </div>
          <div className='options'>
            <MealSelection meal={meal} setMeal={setMeal}/>
            {checkbox}
          </div>
      </div>
    );
  } else {
    return (
      <div className='name-box plus-one'>
        <div className='options'>
          <div className='filler'/>
          {checkbox}
        </div>
      </div>
    );
  }
}

function MealSelection({meal, setMeal, hidden}) {
  return (
    <div className={`meal-selector filler ${hidden ? 'hidden' : ''}`}>
      <select disabled={hidden} onChange={e => setMeal(e.target.value)} value={meal}>
        <option value={Meals.UNKNOWN}>Select meal</option>
        <optgroup label="Meats"></optgroup>
        <option value={Meals.STEAK}>{Meals.STEAK}</option>
        <option value={Meals.CHICKEN}>{Meals.CHICKEN}</option>
        <optgroup label="Vegetarian"></optgroup>
        <option value={Meals.RAVIOLI}>{Meals.RAVIOLI}</option>
      </select>
    </div>
  )
}

function Confirm({party, onConfirm, onBack, onSetRehearsal}) {
  const page = usePageState('details');
  const ri = page?.rehearsal?.timelineItem;

  return (
    <div className='confirm'>
      <span>
        Please confirm that <GuestList party={party} ifNobody="no one from your party"/> will be attending.
        {
          !isAnyoneAttending(party) ? <></> : <> Check back later for more details and meal selections! We can't wait to see you!</>
        }
      </span>
      {party.rehearsal && isAnyoneAttending(party) ? 
        <>
          <SubTitle>Rehearsal dinner</SubTitle>
          <div>
            <b>You are invited to the rehearsal dinner!</b>&nbsp;
            The rehearsal dinner is the night before the wedding (
            <b>{ri?.when}</b> at the <b>{ri?.where}</b>). Check out the <i>Details</i>&nbsp;
            page for more info. Please let us know
            if you can attend so that we can keep track of a rough guest count!<br/><br/>
            <input id='rehearsal-checkbox' type='checkbox' checked={party.isPartyAttendingRehearsal || false} onChange={e => onSetRehearsal(e.target.checked)} />&nbsp;
            <label htmlFor='rehearsal-checkbox'>Yes, we will be attending the rehearsal dinner</label>
          </div>
        </> :
        <></>}
      <div className='actions'>
        <button className='button secondary' onClick={() => onBack()}>Back</button>
        <button className='button' onClick={() => onConfirm()}>Confirm</button>
      </div>
    </div>
  );
}

const Meals = {
  UNKNOWN: 'Unknown',
  STEAK: 'Sirloin Steak',
  CHICKEN: 'Roasted Chicken',
  RAVIOLI: 'Spinach Ravioli',
};

export default RSVP;