import { Title } from '../../components/title/Title';
import { useImage, usePageState } from '../../firebase';
import './Registry.css';

function Registry() {
  const {message2, warning, link} = usePageState('registry');
  const image = useImage('registry/olive_present.jpg');
  return (
    <div className='page registry'>
      <Title>Registry</Title>
      <div className='registry-content'>
        <div>{message2}</div>
        <div><b>{warning}</b></div>
        <a className='registry-link' href={link} rel='noopener' target='_blank'>Amazon Registry</a>
        <img src={image}/>
      </div>
    </div>
  );
}

export default Registry;